
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

interface IParams {
    ProcessID: number;
    OrderID: string;
    Response: string;
    ResultMsg: string;
    ErrMsg: string;
    HostMsg: string;
    CompanyName: string;
    MPAY: string;
    StatusCode: string;
}

export default defineComponent({
    name: "pos-result",
    components: {},
    setup() {
        const store = useStore();

        var theParams = ref<IParams>({
            ProcessID: 0,
            OrderID: "",
            Response: "",
            ResultMsg: "",
            ErrMsg: "",
            HostMsg: "",
            CompanyName: "",
            MPAY: "",
            StatusCode: "",
        });

        var ccvPath = ref("");


        onMounted(() => {
            store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");

            //window.parent.postMessage("POS_RESULT_SUCCESS_MOUNTED_A");
            //window.top.postMessage("POS_RESULT_SUCCESS_MOUNTED_B");

            let vvHref = decodeURI(location.href)

            //Garanti
            if (getParamByName("orderid", vvHref)!==null) {
                console.log("CheckResult Garanti START");
                theParams.value.ProcessID = parseInt(""+Math.random() * 10000);
                theParams.value.OrderID = getParamByName("orderid", vvHref);
                theParams.value.Response = getParamByName("response", vvHref);
                theParams.value.ErrMsg = getParamByName("errmsg", vvHref);
                theParams.value.HostMsg = getParamByName("hostmsg", vvHref);
                theParams.value.CompanyName = getParamByName("companyname", vvHref);

                ccvPath.value = getPathByResult();
            }

            //NomuPay
            if (getParamByName("OrderId", vvHref)!==null) {
                console.log("CheckResult NomuPay START");
                theParams.value.ProcessID = parseInt(""+Math.random() * 10000);
                theParams.value.OrderID = getParamByName("OrderId", vvHref);
                theParams.value.ResultMsg = getParamByName("ResultMsg", vvHref);
                theParams.value.MPAY = getParamByName("MPAY", vvHref);
                theParams.value.StatusCode = getParamByName("StatusCode", vvHref);
            }


            setTimeout(sendToParent, 1000);
        });

        onUnmounted(() => {
            store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
        });

        function getPathByResult() {

            console.log("getPathByResult location.href: "+location.href)
            console.log("getPathByResult theParams.value.CompanyName: "+theParams.value.CompanyName)
            if (theParams.value.CompanyName.includes("localhost")) {
                return "http://"+theParams.value.CompanyName.replace("_", ":");
            }
            return  location.protocol+"//"+location.host;
        }

        function click_Done() {
            console.log("click_Done START ccvPath: "+ccvPath.value );
            //window.parent.postMessage("POS_RESULT_SUCCESS_DONE_A");
            //window.top.postMessage("POS_RESULT_SUCCESS_DONE_B", ccvPath.value);

            sendToParent();
        }

        function sendToParent() {
            window.top.postMessage(""+JSON.stringify(theParams.value), ccvPath.value);
        }

        function getParamByName( name, url ) {
            if (!url) url = location.href;
            //name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
            var regexS = "[\\?&]"+name+"=([^&#]*)";
            var regex = new RegExp( regexS );
            var results = regex.exec( url );
            return results == null ? null : results[1];
        }

        return {
            click_Done,
            theParams,
            ccvPath,
        };
    },
});
